<template>
  <div class="question column is-half">
    <div class="question-content card">
      <div class="card-content" v-html="decodeHtml(question.question[lang])"></div>
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from '@/mixins/question'

export default {
  name: 'ScreenOut',
  mixins: [QuestionMixin],
  async created() {
    await this.$emit("mounted", "is-8");
    await this.$emit("input", { [this.question.qid]: "Y" });
    await this.$emit("screen-out");
  }
}
</script>
